@font-face {
  font-family: 'Herokid';
  src: url('/fonts/herokid/herokid-semibold-reduced.woff2');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proxima-nova/proxima-nova-regular-reduced.woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proxima-nova/proxima-nova-semibold-reduced.woff2');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/proxima-nova/proxima-nova-bold-reduced.woff2');
  font-weight: 700;
  font-display: swap;
}
